<template>
  <v-row
    class="px-10 mb-10"
    dense
  >
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="company.cep"
        @blur="getAddress"
        label="CEP"
        placeholder="xxxxx-xxx"
        v-mask="'#####-###'"
        append-icon="fas fa-search-location"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="9"
    >
      <v-text-field
        v-model="company.address"
        label="Endereço"
        placeholder="Digite o nome da rua"
        :loading="isLoading"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        label="N°"
        v-model="company.number"
        v-mask="'########'"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="10"
    >
      <v-text-field
        label="Complemento"
        v-model="company.complement"
        placeholder="Complemento"
        :loading="isLoading"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="5"
    >
      <v-text-field
        label="Bairro"
        v-model="company.district"
        placeholder="Digite o nome do bairro"
        :loading="isLoading"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="5"
    >
      <v-text-field
        label="Cidade"
        v-model="company.city"
        placeholder="Digite o nome da cidade"
        :loading="isLoading"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        label="UF"
        v-model="company.uf"
        :loading="isLoading"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    company: Object,
  },
  data: () => ({
    uf: [],
    isDisabled: false,
    isLoading: false,
  }),
  methods: {
    getAddress() {
      this.isLoading = true

      this.$api
        .get(`https://cep.awesomeapi.com.br/json/${this.company.cep}`)
        .then((res) => {
          this.company.city = res.data.city
          this.company.district = res.data.district
          this.company.uf = res.data.state
          this.company.address = res.data.address
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
